* {
    font-family: "Cormorant Infant", serif;
    font-weight: 300;
    font-style: normal;
    box-sizing: border-box;
}

textarea {
    resize: none;
    height: 240px;
    border-radius: 6px;
    padding: 10px;
}

.container {
    background-color: rgb(201,255,197);
    width: 100%;
}

.inner-container {
    margin: auto;
    width: 1200px;
    box-sizing: border-box;
}

.image-bgr {
    background: url("../../public/top-desktop.png") no-repeat top center;
    text-align: center;
    padding-top: 400px;
}

.p-together-because {
    text-transform: uppercase;
    font-size: 14pt;
    font-weight: 500;
}

.p-1 {
    font-size: 30pt;
    font-weight: 600;
    letter-spacing: 1px;
}

.p-2 {
    width: 80%;
    font-weight: 500;
    margin: auto;
    text-transform: uppercase;
    font-size: 14pt;
    letter-spacing: 2px;
}

.p-3 {
    font-size: 25pt;
    letter-spacing: 2px;
    font-weight: 500;
}

.p-4 {
    letter-spacing: 1px;
    font-weight: 300;
    font-size: 20pt;
}

.p-5 {
    margin-top: 30px;
    font-size: 17pt;
    letter-spacing: 1px;
    font-weight: 700;
}

.calculator-container {
    border-radius: 100px;
    width: 100%;
    background-color: white;
}

.calculator-date {
    width: 80%;
    margin: auto;
    padding: 16px;
    display: flex;
    justify-content: space-between;
}

.calculator-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
    text-transform: lowercase;
}

.calculator-display {
    font-size: 30pt;
    font-weight: 500;
}

.calculator-item:nth-child(1):nth-child(2) {
    font-size: 20pt;
}

.invitation-container {
    box-sizing: border-box;
    width: 90%;
    margin: auto;
    background-color: white;
}

.invitation {
    padding: 24px 0;
    width: 80%;
    margin: 80px auto;
    align-items: center;
    text-align: center;
}

.invitation:nth-child(1) {
    font-size: 20pt;
}

.invitation h2 {
    font-weight: 700;
    font-size: 28pt;
}

.wedding-img-container > img {
    width: 100%;
    margin-bottom: 16px;
}

.mon-amour {
    font-family: "Lobster", sans-serif;
    font-optical-sizing: auto;
    font-style: italic;
    font-weight: 400;
    letter-spacing: 3px;
    font-size: 27pt;
    width: 80%;
    margin: auto auto 50px auto;
}

.food-menu {
    font-family: "Kaushan Script", cursive;
    font-style: italic;
    font-size: 27pt;
}

.food-menu-text, .your-presence {
    font-size: 19pt;
}

.waiting {
    font-family: "Lobster", sans-serif;
    font-optical-sizing: auto;
    font-style: italic;
    font-size: 30pt;
}

.confirm-button-container {
    display: flex;
    justify-content: space-around;
}

.confirm-button {
    border: none;
    border-radius: 40px;
    background-color: rgb(97, 139, 95);
    padding: 20px;
    cursor: pointer;
    width: 300px;
    font-size: 20pt;
    font-weight: bold;
    color: white;
    margin-top: 24px;
    transition: 100ms ease-in;
}

.confirm-button:hover {
    scale: 103%;
    background-color: rgb(53,102,51);
}

.confirm-button:active {
    scale: 95%;
}

.contact-form {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
}

.form-input {
    padding: 16px;
    border-radius: 6px;
    margin-bottom: 10px;
    font-size: 20pt;
}

.contact-form > div {
    margin: 30px 0 20px 0;
}

.contact-form > div > div {
    width: 200px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.contact-form > div > div > input {
    align-self: center;
}

.contact-form > div > div > label {
    cursor: pointer;
    font-size: 19pt;
}

.schedule-container {
    background-color: rgba(255,255,255,0.5);
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    text-align: center;
    align-items: center;
    flex-direction: column;
    padding: 3%;
}

.schedule-title {
    font-size: 35pt;
    font-weight: bold;
    display: block;
}

.schedule {
    display: flex;
    justify-content: space-between;
    width: 80%;
    font-size: 19pt;
}

.schedule > div {
    margin-bottom: 30px;
}

.schedule > div:nth-child(1) {
    text-align: end;
    width: 20%;
}

.schedule > div:nth-child(2) {
    width: 70%;
}

.schedule > div:nth-child(2) > p:nth-child(1) {
    font-size: 29pt;
}

.schedule > div:nth-child(2) > p:nth-child(2) {
    font-size: 21pt;
    color: dimgray;
}

.schedule > div:nth-child(2) > p:nth-child(3) {
    font-size: 21pt;
}

.map-container {
    margin: auto;
    min-height: 700px;
    width: 90%;
}

.map {
    background: url("../../public/map.png") center/cover;
    min-height: inherit;
    width: 100%;
    cursor: pointer;
}

@media screen and (min-device-width: 661px) and (max-device-width: 1200px) {
    .inner-container {
        width: 100vw;
    }

    .image-bgr {
        background: url("../../public/top-mobile.png") no-repeat top center;
    }

    textarea {
        height: 200px;
    }

    .p-together-because {
        font-size: 12pt;
    }

    .p-1 {
        font-size: 28pt;
    }

    .p-2 {
        font-size: 12pt;
    }

    .p-3 {
        font-size: 23pt;
    }

    .p-4 {
        font-size: 18pt;
    }

    .p-5 {
        font-size: 13pt;
    }

    .calculator-date {
        padding: 10px;
        width: 85%;
    }

    .calculator-display {
        font-size: 28pt;
    }

    .calculator-item:nth-child(1):nth-child(2) {
        font-size: 18pt;
    }

    .invitation {
        padding: 16px 0;
        width: 85%;
        margin: 60px auto;
    }

    .invitation h2 {
        font-size: 26pt;
    }

    .mon-amour {
        font-size: 25pt;
        width: 85%;
        margin: auto auto 40px auto;
    }

    .food-menu {
        font-size: 25pt;
    }

    .food-menu-text, .your-presence {
        font-size: 17pt;
    }

    .waiting {
        font-size: 28pt;
    }

    .confirm-button {
        padding: 16px;
        width: 280px;
        font-size: 18pt;
    }

    .contact-form {
        width: 85%;
    }

    .form-input {
        padding: 16px;
        font-size: 19pt;
    }

    .contact-form > div > div > label {
        font-size: 19pt;
    }

    .schedule-title {
        font-size: 33pt;
    }

    .schedule {
        width: 85%;
        font-size: 17pt;
    }

    .schedule > div:nth-child(2) > p:nth-child(1) {
        font-size: 27pt;
    }

    .schedule > div:nth-child(2) > p:nth-child(2) {
        font-size: 19pt;
    }

    .schedule > div:nth-child(2) > p:nth-child(3) {
        font-size: 19pt;
    }

    .map {
        background: url("../../public/small-map.png") no-repeat center center;
        height: 400px;
        width: auto;
    }
}

@media screen and (min-device-width: 359px) and (max-device-width: 660px) {
    .inner-container {
        width: 100vw;
    }

    .image-bgr {
        background: url("../../public/top-mobile.png") no-repeat top center;
    }

    textarea {
        height: 160px;
    }

    .p-together-because {
        font-size: 9pt;
    }

    .p-1 {
        font-size: 25pt;
    }

    .p-2 {
        font-size: 9pt;
    }

    .p-3 {
        font-size: 20pt;
    }

    .p-4 {
        font-size: 15pt;
    }

    .p-5 {
        font-size: 10pt;
    }

    .calculator-date {
        padding: 10px;
        width: 95%;
    }

    .calculator-display {
        font-size: 25pt;
    }

    .calculator-item:nth-child(1):nth-child(2) {
        font-size: 15pt;
    }

    .invitation {
        padding: 16px 0;
        width: 95%;
        margin: 60px auto;
    }

    .invitation h2 {
        font-size: 23pt;
    }

    .mon-amour {
        font-size: 22pt;
        width: 95%;
        margin: auto auto 40px auto;
    }

    .food-menu {
        font-size: 22pt;
    }

    .food-menu-text, .your-presence {
        font-size: 14pt;
    }

    .waiting {
        font-size: 25pt;
    }

    .confirm-button {
        padding: 16px;
        width: 280px;
        font-size: 15pt;
    }

    .contact-form {
        width: 95%;
    }

    .form-input {
        padding: 14px;
        font-size: 15pt;
    }

    .contact-form > div > div > label {
        font-size: 16pt;
    }

    .schedule-title {
        font-size: 30pt;
    }

    .schedule {
        width: 95%;
        font-size: 14pt;
    }

    .schedule > div:nth-child(2) > p:nth-child(1) {
        font-size: 24pt;
    }

    .schedule > div:nth-child(2) > p:nth-child(2) {
        font-size: 16pt;
    }

    .schedule > div:nth-child(2) > p:nth-child(3) {
        font-size: 16pt;
    }

    .map {
        background: url("../../public/small-map.png") no-repeat center center;
        height: 300px;
    }
}





